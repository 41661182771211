@font-face {
  font-family: 'Neuemontreal';
  src: url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de35619f8d606ba4639db5_NeueMontreal-Bold.eot') format('embedded-opentype'), url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de356114bf48361b4a3c38_NeueMontreal-Bold.woff') format('woff'), url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de356116b8751870bf5a24_NeueMontreal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Neuehaasdisplay';
  src: url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de3561aa280e84e8f98635_NeueHaasDisplay-Bold.eot') format('embedded-opentype'), url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de35614b1b9410e7a29859_NeueHaasDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Neuemontreal';
  src: url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de3561bed90c8a8b13b2c3_NeueMontreal-Regular.eot') format('embedded-opentype'), url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de35612849fe7026ad1a3f_NeueMontreal-Regular.woff') format('woff'), url('https://uploads-ssl.webflow.com/60d14f826ab5af3c74cd08ff/60de3561ec0c11e5810b2e65_NeueMontreal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  background-color: black;
}

.store-buttons {
  margin-top: 55px;
}

.store-buttons img {
  height: 46px;
  margin-right: 30px;
}

.store-buttons img.last {
  margin-right: 0px;
}

/* @media (max-width: 720px) { */
  .store-buttons {
    display: block;
    text-align: center;
    margin-bottom: 45px;
  }

  .store-buttons img {
    height: unset;
    width: 40%;
    text-align: center;
    margin: 10px 0px;
    max-width: 180px;
  }
/* } */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
