.logo-container {
  padding: 48px 0 40px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3,1fr);
}

.c-link {
  text-decoration: none;
}